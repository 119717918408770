import {
  Box,
  Container,
  Stack,
  Typography
} from '@mui/material';
import { PostContent } from 'src/sections/dashboard/blog/post-content';

export const HomeFeatures = () => {

  return (
    <Box
      sx={{
        backgroundColor: 'neutral.800',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundImage: 'url("/assets/gradient-bg.svg")',
        color: 'common.white',
        py: '120px'
      }}
    >
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          sx={{ mb: 8 }}
        >
          <Typography
            align="center"
            color="inherit"
            variant="h3"
          >
            Política Interna
          </Typography>

          <Typography
            align="center"
            color="inherit"
            variant="subtitle2"
          >            
            <Container sx={{ pt: 2,pb:0 }}>
              <PostContent  content={`**QUICORNAC S.A.** dedicada a la producción y comercialización de jugos, purés, concentrados congelados y/o asépticos; bebidas, y, subproductos derivados de frutas. Nos comprometemos en todos los niveles de la organización, así como partes interesadas asociadas a nuestro negocio a: `} />
            </Container>
            
            
          </Typography>

          <Typography
            align="left"
            color="inherit"
            variant="subtitle2"
          >            
            <Container sx={{ pb: 1, pt:0 }}>
              <PostContent content={`1. Satisfacer las necesidades de nuestros clientes, cumpliendo con normativas y estándares de calidad, inocuidad, comercio seguro, responsabilidad social, seguridad & salud ocupacional y medio ambiente.  
2. Identificar, monitorear, medir, evaluar y controlar los factores de riesgos asociados con la actividad y contexto de la organización, comprometiendo los recursos disponibles para la toma oportuna de acciones correctivas y/o preventivas.  
3. Establecer, promover, monitorear, verificar, comunicar y mantener actualizado según sea apropiado el cumplimiento de objetivos y metas.  
4. Planificar que, la mejora continua sea adecuada y eficaz para un desarrollo sostenible, de los sistemas integrados de gestión, trabajando en:   
    * Calidad e inocuidad de nuestros productos, con medidas preventivas asociadas para la defensa de los alimentos y la evaluación de la vulnerabilidad al fraude alimentario.  
    * Promover una cultura de inocuidad y calidad, que incluya comunicación, capacitación, retroalimentación y participación de nuestros colaboradores.  
    * Promover la prevención de riesgos de accidentes y enfermedades ocupacionales de nuestros trabajadores.
    * Reducción del impacto ambiental incluyendo el control de desperdicios, pérdidas y emisiones generadas en los procesos.  
    * Control y seguridad de nuestra información. 
    * Control de la infraestructura y prevención de contaminación ilícita.  
    * Fomentar la no participación en actos de sobornos, corrupción, lavado de activos, financiamiento del terrorismo, u otro tipo de práctica empresarial fraudulenta.   
    * Promover, implementar y mantener la participación y comunicaciones efectivas internas y externas, entre nuestros trabajadores y partes interesadas, a través de canales necesarios para la transmisión de información.
    * Formar, capacitar y promover la competencia con nuestros trabajadores y partes interesadas.
    * Respetar y promover el cumplimiento de Derechos humanos.
5. Cumplir con la legislación vigente nacional e internacional, aplicables a la actividad industrial y comercial de la empresa. 
  

Bernhard Frei  
GERENTE GENERAL  
  

Última revisión: 30 de julio de 2024`} />
            </Container>
            
            
          </Typography>
        </Stack>
      
      </Container>
    </Box>
  );
};
