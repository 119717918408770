import { Box, ButtonBase, Stack, SvgIcon } from '@mui/material';
//import { CloudBlank01 } from '@untitled-ui/icons-react';
import { RouterLink } from 'src/components/router-link';
import CreditCard01Icon from 'src/icons/untitled-ui/duocolor/credit-card-01';
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import LayoutAlt02Icon from 'src/icons/untitled-ui/duocolor/layout-alt-02';
import LogOut01Icon from 'src/icons/untitled-ui/duocolor/log-out-01';
import Mail04Icon from 'src/icons/untitled-ui/duocolor/mail-04';
import XSquareIcon from 'src/icons/untitled-ui/duocolor/x-square';
import FilterDramaOutlinedIcon from '@mui/icons-material/FilterDramaOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';

import { paths } from 'src/paths';

const sections = [
  {
    items: [
      {
        title: 'QuicoLynk',
        path: '/dashboard',
        icon: (
          <SvgIcon fontSize="small">
            <RocketLaunchOutlinedIcon />
          </SvgIcon>
        ),
        caption: '',
        children: [
          {
            title: 'Q\'Liquidas',
            path: 'http://quicornac.net:8080/Liquidaciones/',//paths.dashboard.index,
            external: true
          },
          {
            title: 'Q\'Requieres',
            path: 'http://quicornac.net/Requisiciones/',//paths.dashboard.customers.index,
            external:true
          },
          {
            title: 'TTHH',
            path: 'https://www.quicornac.net/tthh',//paths.dashboard.logistics.index,
            external:true
          },
          {
            title: 'CeroPapel',
            path: 'https://quicornac.net/ceropapel/',//paths.dashboard.fileManager,
            external:true
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        title: 'Q-Nube',
        path: 'https://nube.quicornac.net/owncloud/',//paths.dashboard.blog.index,
        external:true,
        icon: (
          <SvgIcon fontSize="small">
            <FilterDramaOutlinedIcon />
          </SvgIcon>
        )
      },
      {
        title: 'Capacitaciones',
        path: 'https://nube.quicornac.net/moodle',//paths.pricing,
        external:true,
        icon: (
          <SvgIcon fontSize="small">
            <MenuBookOutlinedIcon />
          </SvgIcon>
        )
      },
      {
        title: 'Conferencias',
        path: 'https://meet.quicornac.net/',//'https://meet.quicornac.net/',//paths.contact,
        external:true,
        icon: (
          <SvgIcon fontSize="small">
            <VideocamOutlinedIcon />
          </SvgIcon>
        )
      },
      {
        title: 'MAI',
        path: 'http://192.168.40.3/',//paths.contact,
        external:true,
        icon: (
          <SvgIcon fontSize="small">
            <DescriptionOutlinedIcon />
          </SvgIcon>
        )
      },
      /*{
        title: 'Checkout',
        path: paths.checkout,
        icon: (
          <SvgIcon fontSize="small">
            <LogOut01Icon />
          </SvgIcon>
        )
      },
      {
        title: 'Error',
        path: paths[404],
        icon: (
          <SvgIcon fontSize="small">
            <XSquareIcon />
          </SvgIcon>
        )
      }*/
    ]
  }
];

export const PagesPopover = () => (
  <Box
    sx={{
      display: 'grid',
      gap: 3,
      gridTemplateColumns: 'repeat(2, 1fr)',
      p: 3
    }}
  >
    {sections.map((section, index) => {
      return (
        <Stack
          component="ul"
          key={index}
          spacing={0.5}
          sx={{
            listStyle: 'none',
            m: 0,
            p: 0
          }}
        >
          {section.items.map((item) => {
            const linkProps = item.path
              ? item.external
                ? {
                  component: 'a',
                  href: item.path,
                  target: '_blank'
                }
                : {
                  component: RouterLink,
                  href: item.path
                }
              : {};

            return (
              <li key={item.title}>
                <ButtonBase
                  sx={{
                    alignItems: 'center',
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    px: '12px',
                    py: '6px',
                    textAlign: 'left',
                    width: '100%',
                    '&:hover': {
                      backgroundColor: 'action.hover'
                    }
                  }}
                  {...linkProps}>
                  <Box
                    component="span"
                    sx={{
                      alignItems: 'center',
                      color: 'action.active',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      mr: 2,
                      width: 20
                    }}
                  >
                    {item.icon}
                  </Box>
                  <Box
                    component="span"
                    sx={{ flexGrow: 1 }}
                  >
                    <Box
                      component="span"
                      sx={{
                        display: 'block',
                        fontFamily: (theme) => theme.typography.fontFamily,
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: '24px',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {item.title}
                    </Box>
                    {item.caption && (
                      <Box
                        component="span"
                        sx={{
                          color: 'text.secondary',
                          display: 'block',
                          fontFamily: (theme) => theme.typography.fontFamily,
                          fontSize: 12,
                          fontWeight: 400,
                          lineHeight: '18px',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {item.caption}
                      </Box>
                    )}
                  </Box>
                </ButtonBase>
                {item.children && (
                  <Stack
                    component="ul"
                    spacing={0.5}
                    sx={{
                      listStyle: 'none',
                      m: 0,
                      p: 0,
                      pl: 20 + 16 + 'px' // icon size + icon margin
                    }}
                  >
                    {item.children.map((child) => {
                      const linkProps = child.path
                        ? child.external
                          ? {
                            component: 'a',
                            href: child.path,
                            target: '_blank'
                          }
                          : {
                            component: RouterLink,
                            href: child.path
                          }
                        : {};

                      return (
                        <li key={child.title}>
                          <ButtonBase
                            sx={{
                              alignItems: 'center',
                              borderRadius: 1,
                              display: 'flex',
                              justifyContent: 'flex-start',
                              px: '12px',
                              py: '6px',
                              textAlign: 'left',
                              width: '100%',
                              '&:hover': {
                                backgroundColor: 'action.hover'
                              }
                            }}
                            {...linkProps}>
                            <Box
                              component="span"
                              sx={{
                                color: 'text.secondary',
                                display: 'block',
                                fontFamily: (theme) => theme.typography.fontFamily,
                                fontSize: 14,
                                fontWeight: 500,
                                lineHeight: '24px',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {child.title}
                            </Box>
                          </ButtonBase>
                        </li>
                      );
                    })}
                  </Stack>
                )}
              </li>
            );
          })}
        </Stack>
      );
    })}
  </Box>
);
