
import { Box, Container, Stack, Typography,Card,
  CardContent,Unstable_Grid2 as Grid } from '@mui/material';

export const HomeHero = () => {

  return (
    <Box
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundImage: 'url("/assets/gradient-bg.svg")',
        pt: '120px'
      }}
    >
      <Container maxWidth="lg">
        <Box maxWidth="sm">
          <Typography
            variant="h1"
            sx={{ mb: 2 }}
          >
            <Typography
              component="span"
              color="primary.main"
              variant="inherit"
            >
              Nuestra&nbsp;
            </Typography>
             Empresa
          </Typography>
         
        </Box>
        <Stack
            spacing={8}
            sx={{ py: '50px' }}
          >
            
            <Grid
          container
          spacing={3}
        >
            <Grid
              xs={12}
              md={6}
              lg={6}
            >
              <Card sx={{ height: '100%' }}>
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: '100%'
                  }}
                >
                  <Typography
            variant="h4"
            sx={{ mb: 2 }}
          >
            <Typography
              component="span"
              color="text.secondary"
              variant="inherit"
            >
              Misión
            </Typography>
          </Typography>
                  <Typography
                    sx={{
                      flexGrow: 1,
                      mt: 2
                    }}
                  >
                    {'Producir y exportar jugos, puré y concentrados (congelados y/o aséptico) de frutas tropicales, requeridas por nuestros clientes, de acuerdo a fórmulas y estándares de calidad, a través de la operación eficiente y eficaz de un selecto equipo de profesionales entrenados para el control de los estándares de calidad y seguridad alimentaria desarrollado en nuestras instalaciones fabriles.'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={6}
              lg={6}
            >
              <Card sx={{ height: '100%' }}>
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: '100%'
                  }}
                >
                  <Typography
            variant="h4"
            sx={{ mb: 2 }}
          >
            <Typography
              component="span"
              color="text.secondary"
              variant="inherit"
            >
              Visión
            </Typography>
          </Typography>
                  <Typography
                    sx={{
                      flexGrow: 1,
                      mt: 2
                    }}
                  >
                    {'Ser una empresa líder en la producción y exportación de jugos, puré y concentrado (congelados y/o aséptico) de frutas tropicales. Mantener el compromiso de la satisfacción al cliente mediante el aporte de tecnología moderna renovada de acuerdo a las necesidades del mercado así como la capacitación de nuestro recurso humano.'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
        </Grid>
          </Stack>
      </Container>
    </Box>
  );
};
