import { useTheme } from '@mui/material/styles';

export const Logo = () => {
  const theme = useTheme();
  const fillColor = theme.palette.primary.main;
  const image = theme.palette.mode === 'dark' ? "/assets/logo_qe_white.png" :"/assets/logo-quicornac.png" ; //
  return (
  
   <img
        src={image}
        alt="QUICORNAC"
        loading="lazy"
      />
  
  );
};
